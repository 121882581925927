@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
.App {
  font-family: Source Sans Pro !important;
    background-color: white;
}

a:link {
  font-size: 14px;
  line-height: 16px;/* or 114% */
  color: #008694;
  text-decoration: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 8vmin;
  pointer-events: none;
}

.header{
    position: fixed;
    top:0px;
    background: #99DAE0;
    height: 48px;
    width:100%;
    margin:0px auto;
}

.content{
    margin: 48px auto;
    width: 100%;
}


.header-titles{
    float:left;
    margin: 16px 10px 12px 10px;
    font-size: 16px;
    line-height: 20px;/* identical to box height, or 125% */
    color: #FFFFFF;
    font-style: italic;
    vertical-align: middle;
    cursor: pointer;
}

.selected{
    font-weight: bold;
}

.header-logo{
    padding: 10px 10px 10px 10px;
    float:left;
    background: #5BBAC4;
    width: 137px;
    height: 28px;
}

.header-cell{
    text-align: left;
    background: #EFEFEF;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;/* or 114% */
    padding: 10px 10px 10px 10px;
    color: #000000;
}

.decisions-table{
   margin-left: 50px
}

.decision-img{
    margin-right: 10px;
    margin-left: 13px
}

.funding-amount{
    margin-bottom: 8px;
}

.funding-date{
    color: #7f7f80;
}

.startup-row{
  left: 0%;
  right: 0%;
  top: 11.54%;
  bottom: 78.85%;
  vertical-align: top;
    width: 100%;
}

.odd-background{
  background: rgba(153, 218, 224, 0.2);
}

.startup-cell{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  line-height: 18px;
}

.employees{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;/* identical to box height, or 129% */
  text-align: center;
}

.notes-input{
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    background-color: transparent;
    color: black;
}

.header-direction{
    margin-right: 8px;
    font-size: 10px;
}

.notes{
    min-width: 100px;
}

.signals {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    min-width: 160px;
}

.signal{
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 18px;/* identical to box height, or 129% */
    color: #5AA153;
}

.positive{
  color: #5AA153;
}

.negative{
  color: #DB5800;
}

.neutral{
  color: #7F7F80;
}

.funding{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;/* identical to box height, or 129% */
  text-align: center;
}

.col-company{
    min-width: 5%;

}

.col-industry{
    min-width: 5%;

}

.col-decision{
    width:160px
}

.col-description{
    width: 20%;
}

table {
    table-layout: fixed;
    width: 100%;
}

.description-wide{
    width: 25%;

}

.popup-button{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color: #008694;
}

.spinner{
    margin: auto;
    text-align: center;
    vertical-align: center;
    position:fixed;
    top:50%;
    left:50%;
}

.decision{
    background: linear-gradient(180deg, #99DAE0 0%, #5BBAC4 96.88%, #E0E0E0 100%);
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    margin: 2px 2px 2px 2px;
    padding: 0px 0px 0px 0px;
    width: 31px;
    height: 31px;
}