body {
  background-color: hsla(184.77272727272728, 55.70%, 69.02%, 1.00);
  font-family: Source Sans Pro;
}

.welcome {
  background-color: hsla(184.77272727272728, 55.70%, 69.02%, 1.00);
  text-align: center;
}

.div-block {
  display: inline-block;
  max-width: 560px;
  margin-top: 10%;
  text-align: center;
}

.text-block {
  margin-top: 10px;
  margin-bottom: 60px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 50px;
  font-weight: 400;
}

.button {
  padding: 9px 140px 9px 140px;
  border-radius: 5px;
  background-color: #1073cf;
  color: white !important;
  font-family: 'arial';
}

.w-button {
  display: inline-block;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

.button:hover {
  background-color: hsla(208.90052356020942, 85.65%, 37.71%, 1.00);
}

.image {
  max-width: 300px;
  min-width: 0px;
}

.italic-text {
  font-size: 24px !important;
}

@media (max-width: 767px) {
  .div-block {
    max-width: 480px;
  }
}

@media (max-width: 479px) {
  .div-block {
    max-width: 400px;
  }
  .button {
    padding-right: 100px;
    padding-left: 100px;
  }
  .image {
    max-width: 80%;
  }
  .italic-text {
    font-size: 24px;
  }
}

